<template>
  <div id="generalPage" class="app-bar-padding-top">
    <!-- top bar -->
    <CustomAppBar v-if="dealerJSON" @MainMenuClick="$emit('toggleMainMenu')" v-on="$listeners" />
    <!-- top bar -->
    <div id="page-wrapper" class="page-size pa-6">
      <div class="back-button-wrapper mb-6">
        <v-btn to="/" class="font-weight-bold back-button">
          <v-icon small class="mr-2">mdi-arrow-left-circle</v-icon>
          Terug naar home
        </v-btn>
      </div>
      <H1 id="pageTitle" class="mb-4" v-html="pageTitle"></H1>
      <div id="pageText" v-html="pageDescription" class="pb-4"></div>
    </div>
    <!-- Footer -->
    <Footer v-if="dealerJSON"></Footer>
  </div>
</template>

<script>
import CustomAppBar from '@/components/bars/CustomAppBar.vue';
import Footer from '@/components/bars/Footer.vue';
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'page',
  components: {
    CustomAppBar,
    Footer
  },
  data() {
    return {
      pageTitle: '',
      pageDescription: '',
      currentPage: null
    }
  },
  watch: {
    '$route' (to, from) {
      this.currentPage = to.meta.name; // triggers watched method "currentPage()"
      return to, from; // eslint shows error if not mentioned;
    },
    currentPage () {
      var currentVoucherData = null;
      if (this.dealerJSON) {
        // get page data.
        currentVoucherData = this.dealerJSON.pages.find( page =>
          page.menu_name === this.currentPage // compare name of currentPage with each page.menu_name in dealerJSON.pages array
        );
        // if currentVoucherData is not null/undefined.
        if (currentVoucherData != undefined) {
          this.pageTitle = currentVoucherData.page_title;
          this.pageDescription = currentVoucherData.page_body;
        }
      }
    }
  },
  metaInfo() {
    return {
      title: this.pageTitle
    }
  },
  mounted() {
    this.currentPage = this.$route.meta.name; // triggers watched method "currentPage"
  },
  computed: mapState({
    dealerJSON: state => state.dealerJSON // get data from store
  })
}
</script>